import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";

const MapSection = () => {
      return (
            <div className="position-relative sectionMargin">
                  {/* <CustomPattern bg='var(--primary-color)' /> */}
                  <div className="section-map sectionMargin marginBottom">
                        <Container>
                              <Row className="mt-5">
                                    <Col md="12">
                                          <div className="contact-map">
                                                <div className="google-map-embed">
                                                      <iframe
                                                            title="Plumber Pipes"
                                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d304.7654440273907!2d85.30880587560931!3d27.69569768955099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb194dfab3d13f%3A0x5702411693e64bf7!2sPlumber%20Pipes!5e0!3m2!1sen!2snp!4v1743747249648!5m2!1sen!2snp"
                                                            style={{
                                                                  border: 0,
                                                            }}
                                                            allowFullScreen=""
                                                            loading="lazy"
                                                            referrerPolicy="no-referrer-when-downgrade"
                                                      ></iframe>
                                                </div>
                                          </div>
                                    </Col>
                                    <Col md="6"></Col>
                              </Row>
                        </Container>
                  </div>
                  {/* <CustomFooter/> */}
                  
                  {/* <CustomPattern image={true} bg={'transparent'} position={'right center'} extraStyles={{position:'absolute', bottom: '-5px'}}/> */}
            </div>
      );
};

export default MapSection;

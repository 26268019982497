import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import CustomHeadline from "../../../../components/CustomHeadline";
import CommonButton from "../../../../components/CommonButton";
import {
      CountWrapper,
      CounterContainer,
      CounterInfo,
      SectionHomeAbout,
} from "./styles";
import CountUp, { useCountUp } from "react-countup";
import { CompanyImage } from "../../../About/components/WhyUs/styles";

const progress = [
      {
            id: 1,
            title: "Countries Served",
            count: "20",
            icon: "true",
      },
      {
            id: 2,
            title: "Employees",
            count: "190",
            icon: "true",
      },
      {
            id: 3,
            title: "Manufacturing Units",
            count: "20",
      },
];

const HomeAbout = ({ title }) => {
      useCountUp({
            ref: "counter",
            end: 1234567,
            enableScrollSpy: true,
            scrollSpyDelay: 1000,
      });
      return (
            <div className="position-relative  ">
                  <SectionHomeAbout className={`${title ? title : ""}`}>
                        <div className="inner-home__about">
                              <Container>
                                    <Row className="g-5">
                                          <Col md="6">
                                                <CompanyImage>
                                                      <video
                                                            autoPlay={true}
                                                            loop={true}
                                                            muted={true}
                                                            src="/plumber.mp4"
                                                      ></video>
                                                </CompanyImage>
                                          </Col>
                                          <Col md="6">
                                                <div className="inner-home__about--content">
                                                      <CustomHeadline
                                                            titleColor={
                                                                  "#F7BF23"
                                                            }
                                                            subtitleColor={
                                                                  "#fff"
                                                            }
                                                            title="Plumber Pipes Nepal Pvt. Ltd."
                                                            right={true}
                                                            white={true}
                                                      />
                                                      <div className="pipe">
                                                            <img
                                                                  src="/banners/50.png"
                                                                  alt=""
                                                            />
                                                      </div>
                                                      <p className="mt-3 mb-0">
                                                            Plumber Pipes is
                                                            synonymous with
                                                            innovation,
                                                            consistently
                                                            pioneering new
                                                            trends in the piping
                                                            industry. Our
                                                            commitment to
                                                            introducing
                                                            cutting-edge piping
                                                            technologies and
                                                            ongoing innovation
                                                            in both existing and
                                                            new products is at
                                                            the core of our
                                                            mission. This
                                                            dedicated focus
                                                            allows our brand to
                                                            continually raise
                                                            the standards and
                                                            lead the industry by
                                                            setting a compelling
                                                            example
                                                      </p>
                                                      {/* <CounterContainer className="my-4">
                                                            {progress?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <Col
                                                                              key={
                                                                                    index
                                                                              }
                                                                              className="d-flex flex-column align-items-start"
                                                                        >
                                                                              <CountWrapper className="d-flex gap-2">
                                                                                    <CountUp
                                                                                          end={
                                                                                                item.count
                                                                                          }
                                                                                          enableScrollSpy
                                                                                    />
                                                                                    {item.icon && (
                                                                                          <span>
                                                                                                +
                                                                                          </span>
                                                                                    )}
                                                                              </CountWrapper>

                                                                              <CounterInfo>
                                                                                    <span>
                                                                                          {
                                                                                                item.title
                                                                                          }
                                                                                    </span>
                                                                              </CounterInfo>
                                                                        </Col>
                                                                  )
                                                            )}
                                                      </CounterContainer> */}
                                                      <CommonButton
                                                            title={"Learn More"}
                                                            slug={"/about-us"}
                                                            classes="mt-4"
                                                      />
                                                </div>
                                          </Col>
                                    </Row>
                              </Container>
                        </div>
                  </SectionHomeAbout>
            </div>
      );
};

export default HomeAbout;
